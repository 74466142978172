import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthTokenProviderService } from '../services/auth-token-provider.service';
import { LogService } from '../../debug/services/log.service';
import { AuthEnvService } from '../../auth-store/services/auth-env.service';

/**
 * Guard to check if the user is signed in.
 * @param route - The route to check.
 * @param state - The state of the route.
 * @returns True if the user is signed in, false otherwise.
 */
@Injectable()
export class AccessTokenGuard {
  private readonly authTokenProviderService = inject(AuthTokenProviderService);
  private readonly router = inject(Router);
  private readonly authEnvService = inject(AuthEnvService);
  private readonly logService = inject(LogService);


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.logService.log('AccessTokenGuard.canActivate, route: ', route);
    this.logService.log('AccessTokenGuard.canActivate, state: ', state);
    return this.authTokenProviderService.isSignedIn().pipe(
      tap(isSignedIn => {
        if (!isSignedIn) {
          this.authEnvService.preLoginUrl$.next(state.url ?? null);
          this.router.navigateByUrl('/auth/login');
        }
      })
    );
  }
}

export const accessTokenGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  return inject(AccessTokenGuard).canActivate(route, state);
};
